/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Fragment, PureComponent } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { capitalize } from 'lodash'

import { EuiText, EuiHealth, withEuiTheme, EuiSpacer } from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import type { LineItem } from '@modules/cloud-api/v1/types'
import { CuiTable } from '@modules/cui/Table'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { CuiTableColumn } from '@modules/cui/Table'
import { isActiveItem, isExpiredItem, isFutureItem } from '@modules/billing-lib/utils'

import { numericDateTime } from '@/config/dates'

import DetailsGridPopover from '../DetailsGridPopover'

import type { SerializedStyles } from '@emotion/react'
import type { FilterState } from '..'
import type { ReactElement } from 'react'

interface Props {
  initialLoading?: boolean
  filter: FilterState
  rows: EnhancedLineItem[]
}

// TODO remove once API will add end_type property
export interface EnhancedLineItem extends LineItem {
  end_type?: 'depletion' | 'time'
}

class PrepaidsTable extends PureComponent<Props & WithEuiThemeProps> {
  render(): ReactElement {
    const { initialLoading, rows, filter } = this.props
    const gridColumns = this.getGridColumns()

    if (!initialLoading && rows.length === 0) {
      return (
        <EuiText size='s'>
          <FormattedMessage
            data-test-id={`empty-state-filter-${filter}`}
            id='billing-details-summary.empty-grid.no-active-balances'
            defaultMessage='<strong>You have no {filter} balances.</strong> <capitalize>{filter}</capitalize> balances will show up here.'
            values={{
              filter,
              capitalize: (content: string) => capitalize(content),
              strong: (content) => <strong>{content}</strong>,
            }}
          />
        </EuiText>
      )
    }

    return (
      <div>
        <CuiTable<EnhancedLineItem>
          getRowId={(item) => item.id}
          getRowTestSubj={(item) => `table-line-item-${item.id}`}
          initialLoading={initialLoading}
          rows={rows}
          columns={gridColumns.items}
          rowStyle={this.getRowStyle}
          initialSort={gridColumns.initialSort}
          initialSortDirection='asc'
        />
        {this.renderDepletedItemDisclaimer()}
      </div>
    )
  }

  renderBalanceColumn(value: number): ReactElement {
    return (
      <EuiText size='s'>
        <CuiElasticConsumptionUnits unit='none' value={value} />
      </EuiText>
    )
  }

  renderDepletedItemDisclaimer = () => {
    if (this.props.rows.length === 0) {
      return null
    }

    const hasAnyDepletionTypeLineItems = this.props.rows.some(
      ({ end_type }) => end_type === 'depletion',
    )

    if (hasAnyDepletionTypeLineItems) {
      return (
        <Fragment>
          <EuiSpacer />
          <FormattedMessage
            id='depleted-line-item.disclaimer'
            defaultMessage='* This line item will expire/expired when its credits are depleted. No on-demand usage will be associated with this, and it will not be considered for discounting or invoicing purposes.'
          />
        </Fragment>
      )
    }

    return null
  }

  getRowStyle = (lineItem: EnhancedLineItem): SerializedStyles => {
    const { theme } = this.props

    return css(
      !isActiveItem(lineItem, new Date()) && {
        '.euiTableRowCell': {
          color: theme.euiTheme.colors.textDisabled,
        },
      },
    )
  }

  getStatusCell = (props: Pick<EnhancedLineItem, 'start' | 'end' | 'end_type'>) => {
    const date = new Date()
    const { theme } = this.props

    if (isActiveItem(props, date)) {
      return (
        <EuiHealth color={theme.euiTheme.colors.success}>
          <FormattedMessage id='billing-details-summary.active-label' defaultMessage='Active' />
        </EuiHealth>
      )
    }

    if (isExpiredItem(props, date)) {
      if (props.end_type === 'depletion') {
        return (
          <EuiHealth color={theme.euiTheme.colors.mediumShade}>
            <FormattedMessage
              id='billing-details-summary.depleted-label'
              defaultMessage='Depleted'
            />
          </EuiHealth>
        )
      }

      return (
        <EuiHealth color={theme.euiTheme.colors.mediumShade}>
          <FormattedMessage id='billing-details-summary.expired-label' defaultMessage='Expired' />
        </EuiHealth>
      )
    }

    if (isFutureItem(props, date)) {
      return (
        <EuiHealth color={theme.euiTheme.colors.warning}>
          <FormattedMessage id='billing-details-summary.future-label' defaultMessage='Future' />
        </EuiHealth>
      )
    }

    return
  }

  getDepletionDisclaimerAsterix = ({ end_type }: Pick<EnhancedLineItem, 'end_type'>) => {
    if (end_type === 'depletion') {
      return <span>*</span>
    }

    return null
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<EnhancedLineItem>
    items: Array<CuiTableColumn<EnhancedLineItem>>
  } {
    const expirationDateColumn: CuiTableColumn<EnhancedLineItem> = {
      label: (
        <FormattedMessage
          id='prepaid-account-details.expiration-date'
          defaultMessage='Expiration date'
        />
      ),
      render: ({ end }) => (
        <FormattedDate
          data-test-id='expiration-date-column-value'
          value={end}
          {...numericDateTime}
        />
      ),
      sortKey: ({ end }) => new Date(end),
      width: '250px',
    }

    return {
      initialSort: expirationDateColumn,
      items: [
        {
          label: <FormattedMessage id='prepaid-account-details.status' defaultMessage='Status' />,
          render: ({ start, end, end_type }) => (
            <Fragment>
              {this.getStatusCell({ start, end, end_type })}
              {this.getDepletionDisclaimerAsterix({ end_type })}
            </Fragment>
          ),
          width: '110px',
        },
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.initial-balance'
              defaultMessage='Credits'
            />
          ),
          render: ({ ecu_quantity }) => this.renderBalanceColumn(ecu_quantity),
          align: 'right',
        },
        {
          label: (
            <FormattedMessage id='prepaid-account-details.amount-spent' defaultMessage='Used' />
          ),
          render: ({ ecu_balance, ecu_quantity }) =>
            this.renderBalanceColumn(ecu_quantity - ecu_balance),
          align: 'right',
        },
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.remaining-balance'
              defaultMessage='Remaining balance'
            />
          ),
          render: ({ ecu_balance }) => this.renderBalanceColumn(ecu_balance),
          align: 'right',
        },
        expirationDateColumn,
        {
          label: (
            <FormattedMessage
              id='prepaid-account-details.contract-info'
              defaultMessage='Contract info'
            />
          ),
          render: (lineItem) => <DetailsGridPopover details={lineItem} />,
        },
      ],
    }
  }
}

export default withEuiTheme(PrepaidsTable)
